<template>
	<OnSiteMessageStripForm
		:is-loading="isLoading"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import OnSiteMessageStripForm from '@/components/OnSiteMessageStripForm.vue';

import { ROUTE_NAME } from '../enums/route';
import { scrollToTop } from '../assets/js/helpers';

export default {
	name: 'OnSiteMessageStripCreate',

	components: {
		OnSiteMessageStripForm,
	},

	computed: {
		...mapState('onSiteMessages', {
			create: 'create',
		}),

		isLoading() {
			return this.create.isLoading;
		},
	},

	methods: {
		...mapActions({
			showToast: 'toast/showToast',
			createStripCampaign: 'onSiteMessages/createStripCampaign',
		}),

		async handleSubmit(data) {
			try {
				await this.createStripCampaign(data);

				this.$router.push({ name: ROUTE_NAME.ON_SITE_MESSAGE_STRIP_LIST });
			} catch {
				scrollToTop();
			}
		},
	},
};
</script>
